.logo-img {
  max-width: 5.5rem;
  height: auto;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: #ffe4c4;
  padding: 20px 20px;
  list-style-type: none;
  text-align: justify;
}
.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}
.search {
  display: inline-block;
  position: relative;
}
.header a.logo {
  font-size: 25px;
  font-weight: bold;
}
.header a:hover {
  background-color: #812223;
  color: white;
  border: white;
  border-radius: 20px;
}
.header a:nth-child(7):hover {
  background-color: transparent;
  cursor: pointer;
  color: black;
}
.header a:nth-child(1):hover {
  background-color: transparent;
  cursor: pointer;
  color: black;
}
.header a:nth-child(2):hover {
  background-color: transparent;
  cursor: pointer;
  color: black;
}
/* .header-right {
  float: right;
} */

.searchbar {
  height: 50px;
  width: 200px;
}

.search input[type="text"] {
  width: 300px;
  height: 35px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.subbutton {
  background-color: #812222;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 20px 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.9s ease;
}
.subbutton:hover {
  transform: scale(1.1);
  color: rgb(255, 255, 255);
  background-color: #812222;
}
.flip-card {
  background-color: transparent;
  width: 300px;
  height: 350px;
  perspective: 10000px;
  font-family: sans-serif;
}
.tittle {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front,
.flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 110%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid coral;
  border-radius: 1rem;
}
.flip-card-front {
  background: linear-gradient(
    120deg,
    bisque 60%,
    rgb(255, 231, 222) 88%,
    rgb(255, 211, 195) 40%,
    rgba(255, 127, 80, 0.603) 48%
  );
  color: coral;
}
.flip-card-back {
  background: linear-gradient(
    120deg,
    rgb(255, 174, 145) 30%,
    coral 88%,
    bisque 40%,
    rgb(255, 185, 160) 78%
  );
  color: white;
  transform: rotateY(180deg);
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  transform: translate(25%, 0%);
  flex-wrap: wrap;
  gap: 50px;
  transform: translate(5%, 0%);
}
.cards:hover {
  cursor: pointer;
}
.flip-card-front li,
.flip-card-back li {
  list-style-type: none;
  text-align: left;
  margin-left: 25px;
  color: #812222;
  font-size: 30px;
}
.book_image {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
.lis-1::before {
  content: "\1F4D6";
  left: 0;
  color: #812222;
}
@media only screen and (max-width: 767px) {
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ffe4c4;
    padding: 20px 20px;
    list-style-type: none;
    text-align: justify;
  }

  .bdy-section {
    background-color: #ffe4c4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
    .cards {
      width: 150%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translate(15%, 0%);
      flex-wrap: wrap;
    }
  .buttons {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    transform: translate(-125%, 0%);
    /* margin: 50px 50px 30px 600px; */
    width: 280px;
    height: 50px;
    border-radius: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ffe4c4;
    padding: 20px 20px;
    list-style-type: none;
    text-align: justify;
  }
  .flip-card {
    background-color: transparent;
    width: 225px;
    height: 350px;
    perspective: 10000px;
    font-family: sans-serif;
  }
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: translate(-1.5%, 0%);
    flex-wrap: wrap;
  }
  .buttons {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    transform: translate(-125%, 0%);
    width: 280px;
    height: 50px;
    border-radius: 30px;
  }
}
/* Desktop Breakpoint */
/* @media only screen and (max-width: 992px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ffe4c4;
    padding: 20px 20px;
    list-style-type: none;
    text-align: justify;
    transform: translate(-23%,0%);
  }
  .header a {
    float: none;
    display: block;
    text-align: left;
    transform: translate(39.5%,0%);

  }
  
  .header-right {
    transform: translate(-80%,0%);

  }
  .searchbar {
    width: 100%;
    height:auto;
    padding: 10px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translate(8%,0%);

  }
  .subbutton{
    background-color: #812222;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 20px 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.9s ease;
    transform: translate(100%,0%);

  }
  .search button[type="submit"]:hover {
    transform: scale(1.1);
    color: rgb(255, 255, 255);
    background-color: #812222;
  }
} */
/* @media screen and (max-width: 1000px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ffe4c4;
    padding: 20px 20px;
    list-style-type: none;
    text-align: justify;
    transform: translate(-23%,0%);
  }
  .header a {
    float: none;
    display: block;
    text-align: left;
    transform: translate(39.5%,0%);

  }
  
  .header-right {
    transform: translate(-80%,0%);

  }
  .searchbar {
    width: 100%;
    height:auto;
    padding: 10px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translate(8%,0%);

  }
  .subbutton{
    background-color: #812222;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 20px 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.9s ease;
    transform: translate(100%,0%);

  }
  .search button[type="submit"]:hover {
    transform: scale(1.1);
    color: rgb(255, 255, 255);
    background-color: #812222;
  }
} */
