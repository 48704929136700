.adminbookbody {
  height: 200vw;
}
.delete-book-popup {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}


.delete-book-popup p {
  margin-bottom: 20px;
}

.delete-book-popup button {
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #ff4646;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}

.delete-book-popup button:hover {
  background-color: #ff2b2b;
}

.delete-book-popup button:last-child {
  background-color: #ccc;
  color: #333;
}

.delete-book-popup button:last-child:hover {
  background-color: #ff2b2b;

}
.totalcartes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 300px;
  gap: 50px;
  width: 75%;
}
.display-bks {
  width: 330px;
  height: 400px;
}
section.card {
  margin: auto;
  text-align: justify;
  position: relative;
  width: 330px;
  height: 400px;
  background-color: #812222;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  perspective: 1000px;
  transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px #000000;
  color: #ffffff;
}

.card__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #812222;
  transform: rotateX(-90deg);
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card:hover .card__content {
  transform: rotateX(0deg);
}

.card__title {
  margin: 0;
  padding-left: 5px;
  font-size: 24px;
  color: var(--white);
  font-weight: 700;
}

.card:hover svg {
  scale: 0;
}

.card__description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #ffffff;
  line-height: 1.4;
}
.add,
.delete,
.update {
  width: 90px;
  height: 30px;
  border: none;
  margin-left: 30px;
  border-radius: 20px;
  font-size: 22px;
}
.add {
  background-color: #ffe4c4;
  color: black;
}
.delete {
  background-color: rgba(50, 17, 17, 0.659);
  color: white;
}
.update {
  background-color: orange;
  color: white;
}
.button {
  appearance: none;
  font: inherit;
  border: none;
  background: none;
  cursor: pointer;
}
.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #4caf50;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1150px;
  height: 550px;
  background-color: #fff;
  box-shadow: 0 15px 30px 0 rgba(0, 125, 171, 0.15);
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-54%, -50%);
  overflow-y: auto;
  z-index: 2;
}
.modal__header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal__body {
  padding: 1rem 1rem;
}

.modal__footer {
  padding: 0 1.5rem 1.5rem;
}

.modal__title {
  font-weight: 700;
  font-size: 1.25rem;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.15s ease;
}

.button--icon {
  width: 2.5rem;
  height: 2.5rem;
  background-color: transparent;
  border-radius: 0.25rem;
}

.button--icon:focus,
.button--icon:hover {
  background-color: #ededed;
}

.button--primary {
  background-color: #812222;
  color: #fff;
  padding: 1.5rem 1.25rem;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 0.875rem;
}

.input {
  display: flex;
  flex-direction: column;
}

.input + .input {
  margin-top: 1.75rem;
}

.input__label {
  font-weight: 700;
  font-size: 0.875rem;
}

.input__field {
  display: block;
  margin-top: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 0.75rem 0.75rem;
  transition: 0.15s ease;
}

.input__field:focus {
  outline: none;
  border-color: #007dab;
  box-shadow: 0 0 0 1px #007dab, 0 0 0 4px rgba(0, 125, 171, 0.25);
}

.input__field--textarea {
  resize: none;
  min-height: 100px;
  max-width: 100%;
}

.input__description {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  color: #8d8d8d;
}
.updateBook {
  width: 1150px;
  height: 500px;
  background: white;
  margin-left: 300px;
  border-radius: 10px;
  box-shadow: 0 15px 30px 0 rgba(0, 125, 171, 0.15);
overflow-y: scroll;
z-index: 2;
}

