.moreinfo{
  /* background-image: url("../Images/library-book-bookshelf-read.jpg"); */
  padding: 30px;
}
.card {
  padding: 20px;
  width: 900px;
  height: 500px;
  border-radius: 20px;
  background: #e8e8e8;
  box-shadow: 5px 5px 6px #dadada, -5px -5px 6px #f6f6f6;
  transition: 0.4s;
  margin: auto;
  display: flex;
  flex-direction: row;
  text-align: justify;
}
.card:hover {
  translate: 0 -10px;
}
.card-title {
  font-size: 18px;
  font-weight: 600;
  color: #812222;
  margin: 15px 0 0 10px;
}
.card-image {
  min-height: 300px;
  background-color: #c9c9c9;
  border-radius: 15px;
  box-shadow: inset 8px 8px 10px #c3c3c3, inset -8px -8px 10px #cfcfcf;
}
.card-body {
  margin: 13px 0 0 10px;
  color: rgb(31, 31, 31);
  font-size: 15px;
}
.bk-img {
  width: stretch;
  height: 500px;
  border-radius: 20px;
}
.by-name {
  font-weight: 700;
}
.sticky {
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  align-items: center;
justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  gap: 0;
  background-color: #812222;
  font-size: 20px;
  z-index: 2;
  color: white;
  width: 200px;
  height: 50px;
  background-color: #812222;
  color: white;
  border:none;
  border-radius: 30px;
  margin-top: 10px;
  font-size: large;
}
.handleHome{
  width: 200px;
  height: 50px;
  background-color: #812222;
  color: white;
  border:none;
  border-radius: 30px;
  margin-top: 10px;
  font-size: large;
}
/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  /* Your mobile styles go here */
  .moreinfo{
    /* background-image: url("../Images/library-book-bookshelf-read.jpg"); */
    padding: 30px;
  }
  .card {
    padding: 20px;
    width: 383.5px;
    height: 900px;
    border-radius: 20px;
    background: #e8e8e8;
    box-shadow: 5px 5px 6px #dadada, -5px -5px 6px #f6f6f6;
    transition: 0.4s;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: justify;
  }
  .card:hover {
    translate: 0 -10px;
  }
  .card-title {
    font-size: 18px;
    font-weight: 600;
    color: #812222;
    margin: 15px 0 0 10px;
  }
  .card-image {
    min-height: 300px;
    background-color: #c9c9c9;
    border-radius: 15px;
    box-shadow: inset 8px 8px 10px #c3c3c3, inset -8px -8px 10px #cfcfcf;
  }
  .card-body {
    margin: 13px 0 0 10px;
    color: rgb(31, 31, 31);
    font-size: 15px;
  }
  .bk-img {
    width: 300px;
    height: 300px;
    border-radius: 20px;
  }
  .by-name {
    font-weight: 700;
  }
  .sticky {
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    text-align: center;
    align-items: center;
  justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 0;
    gap: 0;
    background-color: #812222;
    font-size: 20px;
    z-index: 2;
    color: white;
    width: 200px;
    height: 50px;
    background-color: #812222;
    color: white;
    border:none;
    border-radius: 30px;
    margin-top: 10px;
    font-size: large;
  }
  .handleHome{
    width: 200px;
    height: 50px;
    background-color: #812222;
    color: white;
    border:none;
    border-radius: 30px;
    margin-top: 10px;
    font-size: large;
  }
}

/* Styles for tablets and small screens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Your tablet styles go here */
  .card {
    padding: 20px;
    width: 900px;
    height: 500px;
    border-radius: 20px;
    background: #e8e8e8;
    box-shadow: 5px 5px 6px #dadada, -5px -5px 6px #f6f6f6;
    transition: 0.4s;
    margin: auto;
    display: flex;
    flex-direction: row;
    text-align: justify;
  }
}

/* Styles for laptops and larger screens */
@media only screen and (min-width: 1024px) {
  /* Your laptop styles go here */
  .card {
    padding: 20px;
    width: 900px;
    height: 500px;
    border-radius: 20px;
    background: #e8e8e8;
    box-shadow: 5px 5px 6px #dadada, -5px -5px 6px #f6f6f6;
    transition: 0.4s;
    margin: auto;
    display: flex;
    flex-direction: row;
    text-align: justify;
  }
}
