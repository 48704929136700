.blockquote {
  padding: 60px 80px 40px;
  position: relative;
}
.blockquote p {
  font-family: "Utopia-italic";
  font-size: 35px;
  font-weight: 700px;
  text-align: center;
}
.blockquote:before {
position: absolute;
font-family: 'FontAwesome';
top: 0;
content:"\f10d";
font-size: 200px;
color: rgba(0,0,0,0.1); 
}
.blockquote::after {
  content: "";
  top: 20px;
  left: 50%;
  margin-left: -100px;
  position: absolute;
  border-bottom: 3px solid #bf0024;
  height: 3px;
  width: 200px;
}
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
.otro-blockquote{
font-size: 1.4em;
width:45%;
margin:50px auto;
font-family:Open Sans;
font-style:italic;
color: #555555;
padding:1.2em 30px 1.2em 75px;
border-left:8px solid #812222 ;
line-height:1.6;
position: relative;
word-break: break-all;
text-align: justify;
background:#EDEDED;
transform: translate(15%, 0%);
}

.otro-blockquote::before{
font-family:Arial;
content: "\201C";
color:#812222;
font-size:4em;
position: absolute;
left: 10px;
top:-10px;
}

.otro-blockquote::after{
content: '';
}

.otro-blockquote span{
display:block;
color:#333333;
font-style: normal;
font-weight: bold;
margin-top:1em;
}
.quotes{
  margin: 10px 10px 10px 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Style for the first popup */
.popup1 {
  background-color: #f0f0f0;
}

/* Style for the second popup */
.popup2 {
  background-color: #812222;
  color: white;
}

/* Style for the third popup */
.popup3 {
  background-color: #90ee90;
}

/* Overlay style to dim the background when popup is active */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Button style to trigger popups */
.popup-btn {
  margin: 10px;
  padding: 10px;
  background-color: #812222;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Center the button */
.center {
  text-align: center;
  transform: translate(-25%,0%);
}
/* Add these styles to your existing CSS file or create a new one */

/* Delete Popup Styles */
.delete-popup {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.delete-popup h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.delete-popup p {
  margin-bottom: 20px;
}

.delete-popup button {
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #ff4646;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}

.delete-popup button:hover {
  background-color: #ff2b2b;
}

.delete-popup button:last-child {
  background-color: #ccc;
  color: #333;
}

.delete-popup button:last-child:hover {
  background-color: #999;
}
.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #4caf50;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}