body {
  overflow-x: hidden;
}
.footer {
  width: 1000px;
  height: 100%;
  background-color: #812222;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  transform: translate(-1%,2%);
} 
.categories {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 30px;
  color: white;
}
.contact-us {
  display: flex;
  flex-direction: column;
  color: white;
}
.newsletter {
  display: flex;
  flex-direction: column;
  color: white;
}
.textarea {
  position: relative;
  border-radius: 20px;
  max-width: 640px;
  max-height: 65px;
  resize: none;
}
.subscribe {
  margin-top: 20px;
  width: 150px;
  height: 50px;
  border-radius: 30px;
  font-size: 25px;
  border: none;
  color: #812222;
}
.p1,
.p2,
.p3,
.p4,
.p5,
.p6,
.p7,
.p8,
.p9,
.p10,
.p11 {
  font-size: 20px;
}
.dark-mode {
  background-color: black;
  color: white;
}


/* xs */
@media (max-width: 475px) {
  .footer {
    height: 100vh;
    background-color: #812222;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px; /* Adjusted gap value */
    width: 189%; /* Set to 100vw to match viewport width */
  transform: translate(-1%,2%);

  }

  .textarea {
    position: relative;
    border-radius: 20px;
    max-width: 280px;
    max-height: 200px;
  }
}

/* sm */
@media (min-width: 476px) and (max-width: 640px) {
  .footer {
    height: 90vh;
    background-color: #812222;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px; /* Adjusted gap value */
    width: 170vw; /* Set to 100vw to match viewport width */
  }
  .textarea {
    position: relative;
    border-radius: 20px;
    max-width: 520px;
    max-height: 200px;
  }
}

/* md */
@media (min-width: 641px) and (max-width: 767px) {
  .footer {
    height: auto;
    background-color: #812222;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px; /* Adjusted gap value */
    width: 163%; /* Set to 100vw to match viewport width */
  transform: translate(-1%,2%);

  }
}

/* lg */
@media (min-width: 768px) and (max-width: 1023px) {
  .footer {
    padding-left: 10px;
    height: auto;
    background-color: #812222;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px; /* Adjusted gap value */
    width: 100%; /* Set to 100vw to match viewport width */
    overflow-x: hidden;
  transform: translate(-1%,2%);

  }
}

/* xl */
@media (min-width: 1024px) and (max-width: 1279px) {
  .footer {
    /* Adjust styles as needed */
  }
}

/* 2xl */
@media (min-width: 1280px) and (max-width: 1535px) {
  .footer {
    /* Adjust styles as needed */
    padding-left: 10px;
    height: auto;
    background-color: #812222;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px; /* Adjusted gap value */
    width: 100%; /* Set to 100vw to match viewport width */
    overflow-x: hidden;
  transform: translate(-1%,2%);

  }
}

/* 3xl */
@media (min-width: 1536px) {
  .footer {
    /* Adjust styles as needed */
    height: auto;
    background-color: #812222;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 280px; /* Adjusted gap value */
    width: 110%; /*Set to 100vw to match viewport width*/
    overflow-x: hidden;
  transform: translate(-1%,2%);

  }
}
