body{
  background-color: #ffe4c4;
}
.blockquote {
  padding: 60px 80px 40px;
  position: relative;
}
.blockquote p {
  font-family: "Utopia-italic";
  font-size: 35px;
  font-weight: 700px;
  text-align: center;
}
.blockquote:before {
position: absolute;
font-family: 'FontAwesome';
top: 0;
content:"\f10d";
font-size: 200px;
color: rgba(0,0,0,0.1); 
}
.blockquote::after {
  content: "";
  top: 20px;
  left: 50%;
  margin-left: -100px;
  position: absolute;
  border-bottom: 3px solid #bf0024;
  height: 3px;
  width: 200px;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
.other-blockquote{
font-size: 1.4em;
width: 65%;
margin:50px auto;
font-family:Open Sans;
font-style:italic;
color: #555555;
padding:1.2em 30px 1.2em 75px;
border-left:8px solid #812222 ;
line-height:1.6;
position: relative;
background:#EDEDED;
}

.other-blockquote::before{
font-family:Arial;
content: "\201C";
color:#812222;
font-size:4em;
position: absolute;
left: 10px;
top:-10px;
}

.other-blockquote::after{
content: '';
}

.other-blockquote span{
display:block;
color:#333333;
font-style: normal;
font-weight: bold;
margin-top:1em;
}
.quotes1{
  display: flex;
  flex-direction: row;  
  justify-content: center;
  align-items: center;
  flex-wrap:wrap;
  gap: 20px;
  /* display: inline-flex;
  flex-wrap:wrap; */
}
.other-blockquote{
  width: 300px;
}
.quotes1:hover{
  cursor: pointer;
}