.main {
  background-image: url("../Images/books image.jpg");
  overflow: hidden;
}
.body2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffe4c4;
  height: 700px;
  width: 400px;
  margin: auto;
}

.title1 {
  text-align: center;
  font-size: 30px;
  color: #812222;
}
.s-up {
  text-align: center;
  font-size: 40px;
  color: #812222;
  font-weight: 400;
}
.div-img {
  text-align: center;
}
.book-image {
  width: 200px;
  height: 200px;
}
.input22,
.input33 {
  border: none;
  outline: none;
  background-color: transparent;
}
.inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.mail {
  width: 30px;
  text-align: center;
}
.icon-i1 {
  display: flex;
  flex-direction: row;
}
.icon-i22 {
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid #812222;
}
.icon-i33 {
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid #812222;
}
.col {
  transform: translate(0%, 0%);
}
.signinbutton {
  width: 200px;
  height: 40px;
  background-color: #812222;
  color: white;
  border-radius: 15px;
  font-size: large;
}
.buttonfors-up {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.question {
  text-align: center;
  font-size: 14.5px;
  border-bottom: 3px solid #812222;
}
.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #4caf50;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
