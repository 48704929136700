.book1 {
  word-break: break-all;
  font-size: 25px;
  list-style-type: none;
  margin: auto;
  border-radius: 10px;
  width: 250px;
  height: 300px;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  -webkit-transform: preserve-3d;
  -ms-transform: preserve-3d;
  transform: preserve-3d;
  -webkit-perspective: 2000px;
  perspective: 3000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  color: #000;
}
.cover1 {
  list-style-type: none;
  top: 0;
  position: absolute;
  background-color: #812222;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform-origin: 0;
  -ms-transform-origin: 0;
  transform-origin: 0;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  font-size: 30px;
}
.book1:hover .cover1 {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: rotatey(-90deg);
  -ms-transform: rotatey(-90deg);
  transform: rotatey(-90deg);
}

p {
  font-size: 20px;
  font-weight: bolder;
}
.users-displayed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px 30px 30px 330px;
  gap: 150px;
}
.userupdate{
background-color: #812222;
border: none;
border-radius: 20px;
color: white;
width:100px;
height: 50px;
}
.userdelete{
  background-color: #812222;
  border: none;
  border-radius: 20px;
  color: white;
  width:100px;
  height: 50px;
  }
  .dropdown{
    width: 50%;
    margin: auto;
  }
  .modal2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1150px;
    height: 550px;
    background-color: #fff;
    box-shadow: 0 15px 30px 0 rgba(0, 125, 171, 0.15);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-54%, -50%);
    overflow-y: auto;
    z-index: 2;
  }
  .modal3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1150px;
    height: 550px;
    background-color: #fff;
    box-shadow: 0 15px 30px 0 rgba(0, 125, 171, 0.15);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-54%, -50%);
    overflow-y: auto;
    z-index: 2;
  }
  .updateuser{
    border: none;
    background-color: #812222;
    color: white;
    border-radius: 10px;
    margin-top: 10px ;
    width: 130px;
    height: 40px;
  }
  .deleteuser{
    width: 150px;
    height: 35px;
    background-color: #812222;
    color: white;
    border-radius: 10px;
    margin-left: 10px;

  }
  .canceldeletion{
    width: 150px;
    height: 35px;
    background-color: #812222;
    color: white;
    border-radius: 10px;
    margin-left: 10px;
  }
  .closebtn{

    width: 100px;
    height: 35px;
    background-color: #812222;
    color: white;
    border-radius: 10px;
  }
  .closeimg{
    width:  45px;
    height: 45px;
  }