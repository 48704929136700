.body3 {
  background-color: #ffe4c4;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800&family=Poppins:wght@300;400;500;600;700&display=swap");
:root {
  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --primary-color: #f4f2f2;
  --primary-color-light: #f4f2f2;
  --toggle-color: #ddd;
  --text-color: white;
  --dark-color: #000;
  --trans-02: all 0.2s ease;
  --trans-03: all 0.3s ease;
  --trans-04: all 0.4s ease;
  --trans-04: all 0.5s ease;
}
.sidebar .text {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  white-space: unset;
  opacity: 1;
}
.sidebar .image {
  min-width: 60px;
  display: flex;
  align-items: center;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0px;
  height: 100%;
  width: 250px;
  background-color: var(--sidebar-color);
  padding: 14px 14px;
  transition: var(--trans-02);
  background-color: #812222;
}
.sidebar header {
  position: relative;
}
.sidebar .image-text img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.sidebar header .image-text {
  display: flex;
  align-items: center;
}
.header .image-text .header-text {
  display: flex;
  flex-direction: column;
}
.header-text .main {
  font-weight: 600;
}
.header-text .sub {
  margin-top: -2px;
}
.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: var(--dark-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--sidebar-color);
  font-size: 22px;
}
.sidebar li {
  height: 50px;
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
}
.sidebar li .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 20px;
}
.sidebar li .icons,
.sidebar li .text {
  color: var(--text-color);
  transition: var(--trans-03);
  margin-right: -5px;
}
.sidebar li a{
  height: 100%;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-right: 60px;
  border-radius: 6px;
  text-align: left;
}
.sidebar li a:hover {
  background: var(--primary-color);
}
.sidebar li a:hover .icons,
.sidebar li a:hover .text {
  color: var(--dark-color);
}

.sidebar .menu-bar {
  height: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar li .user-img {
  height: 60%;
  width: 30%;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-right: 20px;
  border-radius: 6px;
  text-align: left;
}
.user-img {
  margin-left: 10px;
  color: black;
}
.bx-bell:before {
  content: "\1F4D6";
}
.icons1{
    height: 70%;
    width: 20%;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-right: 50px;
    border-radius: 6px;
    transition: var(--trans-04);
    text-align: left;
}
.icons2{
    height: 60%;
    width: 20%;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-right: 50px;
    border-radius: 6px;
    transition: var(--trans-04);
    text-align: left;
}
.quotations-icon{
    width: 25px;
    margin: 10px 15px 10px 15px;
}
.book{
     width: 30px;
    height: 30px;
    margin: 10px 0px 5px 10px;
}
.sidebar li .home-img{
    height: 60%;
    width: 20%;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-right: 10px;
    border-radius: 6px;
    /* transition: var(--trans-04); */
    color: black;
    text-align: left; 
}